<template>
  <div class="context-menu" :class="{ 'context-menu--flat-top': isFlatTop }" v-if="_isOpen">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    isOpen: Boolean,
    isFlatTop: Boolean,
  },
  data() {
    return {
      _isOpen: this.isOpen,
    };
  },
  methods: {
    close() {
      this._isOpen = false;
      this.$emit('closed');
    },
    clickOutside(event) {
      if (event.target.closest('.context-menu')) {
        return;
      }

      if (event.target.closest('.select-with-search')) {
        return;
      }

      this.close();
    },
  },
  watch: {
    isOpen(value) {
      this._isOpen = value;
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutside);
  },
  unmounted() {
    document.removeEventListener('click', this.clickOutside);
  },
};
</script>

<style scoped>
.context-menu {
  position: relative;
  transform-origin: top;
  content-visibility: auto;
  overflow: hidden;

  background-color: var(--color-natural-white);
  border-radius: var(--global-border-radius);

  box-shadow: 0 4px 8px 0 var(--color-shadow-context-menu);

  z-index: 100;
}

.context-menu--flat-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.context-menu--flat-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.context-menu--disable-shadows {
  box-shadow: none;
}
</style>
