<template>
  <div v-if="item" class="context-menu-item-container">
    <div class="context-menu-item-label">
      <div class="context-menu-item-icon-box">
        <i class="fa" :class="getIcon(item)"></i>
      </div>
      <div class="title-box">
        <div class="item-label">{{ item.title }}</div>
        <div class="item-subline text-muted" v-html="item.subtitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextMenuItem',
  props: ['item'],
  methods: {
    getIcon(item) {
      return item?.icon || '';
    },
  },
};
</script>

<style scoped>
.context-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  min-height: 3rem;
  cursor: pointer;
  flex-wrap: wrap;
}

.context-menu-item-container:hover {
  background-color: #f5f6f8;
}

.context-menu-item-icon-box {
  width: 24px;
  height: 24px;
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.context-menu-item-label {
  display: flex;
}

.item-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-subline {
  font-size: 0.8rem;
}
</style>
