<template>
  <div class="select-with-search search-wrapper">
    <div class="input-container" :class="{ 'input-container--has-bottom-border-radius': !showContextMenu }">
      <div class="input-icon-box main">
        <i :class="icon"></i>
      </div>
      <input
        ref="input"
        :id="inputId"
        autocomplete="off"
        type="text"
        class="input"
        placeholder="Search"
        :tabindex="tabindex"
        v-model="_inputValue"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @input="onInputInput"
        @keydown="onInputKeyDown"
      />
      <div class="input-icon-box action" :class="{ hidden: !_inputValue }" @click.prevent="clear">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <context-menu :isOpen="showContextMenu" :isFlatTop="true" @closed="showContextMenu = false">
      <div v-if="items && Array.isArray(items) && items.length" class="items-box">
        <ContextMenuItem v-for="item in items" :item="item" @click="onItemClicked(item)" />
      </div>
      <div v-else class="p-3">No data</div>
    </context-menu>

    <slot></slot>
  </div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import { debounce } from '@/utils';

export default {
  name: 'SelectWithSearch',
  components: { ContextMenu, ContextMenuItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    inputId: {
      type: String,
      required: false,
    },
    inputValue: {
      type: String,
      required: false,
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearOnFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'fa fa-search',
    },
    tabindex: {
      type: Number,
      required: false,
    },
    handleBlur: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      _inputValue: this.inputValue,
      showContextMenu: false,

      loading: false,
      enterPressed: false,
    };
  },
  methods: {
    clear() {
      this.onSearch();
      this.$emit('clear');
    },

    openContextMenu() {
      requestAnimationFrame(() => {
        this.showContextMenu = true;
      });
    },

    closeContextMenu() {
      requestAnimationFrame(() => {
        this.showContextMenu = false;
      });
    },

    onSearch(value) {
      this.loading = true;
      this._inputValue = value;
      this._emitSearch(this._inputValue);
    },

    onInputFocus() {
      if (this.clearOnFocus) {
        this.clear();
      } else {
        this.onSearch(this._inputValue);
      }

      this.openContextMenu();
    },

    onInputBlur() {
      if (this.$props.handleBlur) {
        setTimeout(() => this.closeContextMenu(), 200);
      }
    },

    onInputInput(event) {
      this.onSearch(event.target.value);
    },

    async onInputKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (this.enterPressed) {
          return;
        }

        this.enterPressed = true;

        while (this.loading) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }

        this.enterPressed = false;

        if (this.items.length) {
          this.onItemClicked(this.items[0]);
        }
      } else if (event.key === 'Escape') {
        this.$refs.input?.blur();
        this.closeContextMenu();
      }
    },

    onItemClicked(item) {
      this.closeContextMenu();
      this.$refs.input?.blur();

      this.onSearch(this.clearOnSelect ? null : item.title);

      if (typeof item.onClick === 'function') {
        item.onClick();
      }
    },

    _emitSearch(value) {
      this.$emit('search', value);
    },
  },
  mounted() {
    this._emitSearch = debounce(this._emitSearch.bind(this), 500);
  },
  watch: {
    inputValue(value) {
      this._inputValue = value;
    },
    items(items, oldItems) {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  position: relative;
  color: #212529;
}

.context-menu {
  width: 100%;
  position: absolute;
  top: 100%;
}

.context-menu--position-relative {
  position: relative !important;
}

.input-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  background-color: white;
  border: 1px solid #ced4da;

  border-top-left-radius: var(--global-border-radius);
  border-top-right-radius: var(--global-border-radius);
}

.input-container--has-bottom-border-radius {
  border-bottom-left-radius: var(--global-border-radius);
  border-bottom-right-radius: var(--global-border-radius);
}

.input {
  width: 100%;
  padding: calc(0.375rem + 1px) 0;
  border: none;
  margin: 0 10px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.input:focus {
  outline: 0 none;
}

.input-icon-box {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-icon-box.action {
  cursor: pointer;
  visibility: visible;
}

.input-icon-box.hidden {
  visibility: hidden;
}

.input-icon-box.main {
  flex: 0;
}

.items-box {
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
